import React from 'react';
import Link from 'gatsby-link';
import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout footer={false}>
    <div className="hero">
      <div className="container">
        <div className="intro">
          <div className="intro__wrap">
            <h1 className="intro__title">Dan Brown</h1>
            <p className="intro__text">
              I am a creator,{' '}
              <a href="http://www.github.com/slightlyoffbeat" target="blank">
                developer
              </a>, product guy, strategist, homebrewer,{' '}
              <a
                href="https://www.strava.com/athletes/573766"
                target="_blank"
                rel="noopener noreferrer"
              >
                runner
              </a>, sock enthusiast, beard evangelist, writer, drummer,
              adventurer, Oxford comma advocate, and{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/slightlyoffbeat"
              >
                human Swiss Army Knife
              </a>.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.twitter.com/slightlyoffbeat"
            >
              <i className="intro__icon fa fa-twitter" aria-hidden="true" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://www.github.com/slightlyoffbeat"
            >
              <i className="intro__icon fa fa-github" aria-hidden="true" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/in/slightlyoffbeat"
            >
              <i className="intro__icon fa fa-linkedin" aria-hidden="true" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/slightlyoffbeat"
            >
              <i className="intro__icon fa fa-instagram" aria-hidden="true" />
            </a>
            {/* 
            <Link target="_blank" rel="noopener noreferrer" to="/contact">
              <i className="intro__icon fa fa-envelope-o" aria-hidden="true" />
            </Link>
            */}
          </div>

          {/*
          <span className="scroll-down">
            <span>Scroll Down</span>
          </span>
          */}
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
